import React, { useId } from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const uniqeIdGradient0 = useId();
  const uniqeIdGradient1 = useId();
  const uniqeIdGradient2 = useId();

  const uniqeIdFilter0 = useId();
  const uniqeIdFilter1 = useId();

  return (
    <svg
      width="78"
      height="34"
      viewBox="0 0 78 34"
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path
        d="M75.278 2.56408C73.4608 1.45594 71.0894 2.03074 69.9812 3.84792L63.3472 14.7267L56.9191 4.18559C55.811 2.36841 53.4396 1.79363 51.6224 2.90176C49.8052 4.0099 49.2304 6.38134 50.3386 8.19852L58.8334 22.1287L55.1681 28.1392C54.06 29.9563 54.6348 32.3278 56.452 33.4359C58.2692 34.5441 60.6406 33.9693 61.7487 32.1521L76.5618 7.86084C77.67 6.04366 77.0952 3.67222 75.278 2.56408Z"
        fill={`url(#${uniqeIdGradient0}_linear_288_161440)`}
      />
      <g filter={`url(#${uniqeIdFilter0}_d_288_161440)`}>
        <path
          d="M50.5911 3.84791C51.6993 2.03073 54.0707 1.45594 55.8879 2.56408C57.7051 3.67222 58.2799 6.04366 57.1717 7.86084L47.7137 23.3705C47.4074 24.0667 46.8924 24.6795 46.1938 25.1055C45.5601 25.4919 44.8591 25.6737 44.1677 25.6695C43.4764 25.6737 42.7754 25.4919 42.1418 25.1055C41.4432 24.6795 40.9282 24.0667 40.6219 23.3705L34.2625 12.9422L27.6281 23.8217C26.5199 25.6389 24.1485 26.2137 22.3313 25.1055C20.5141 23.9974 19.9393 21.6259 21.0475 19.8088L30.7806 3.84791C31.5417 2.59991 32.8986 1.93791 34.2625 2.00459C35.6265 1.93791 36.9834 2.59991 37.7445 3.84791L44.1678 14.3812L50.5911 3.84791Z"
          fill={`url(#${uniqeIdGradient1}_linear_288_161440)`}
        />
      </g>
      <g filter={`url(#${uniqeIdFilter1}_d_288_161440)`}>
        <path
          d="M14.6972 2.00152C13.3611 1.96471 12.043 2.62496 11.2972 3.84797L1.56408 19.8088C0.455943 21.626 1.03073 23.9974 2.84791 25.1056C4.66509 26.2137 7.03653 25.6389 8.14467 23.8217L14.6972 13.0765L21.2498 23.8217C22.358 25.6389 24.7294 26.2137 26.5466 25.1056C28.3638 23.9974 28.9385 21.626 27.8304 19.8088L18.0973 3.84797C17.3515 2.62496 16.0334 1.96471 14.6972 2.00152Z"
          fill={`url(#${uniqeIdGradient2}_linear_288_161440)`}
        />
      </g>
      <defs>
        <filter
          id={`${uniqeIdFilter0}_d_288_161440`}
          x="19.5034"
          y="1.34664"
          width="39.8658"
          height="26.283"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.326679" dy="0.653358" />
          <feGaussianBlur stdDeviation="0.653358" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_288_161440"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_288_161440"
            result="shape"
          />
        </filter>
        <filter
          id={`${uniqeIdFilter1}_d_288_161440`}
          x="0.0199635"
          y="0.366667"
          width="30.0079"
          height="26.283"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.326679" dy="-0.326679" />
          <feGaussianBlur stdDeviation="0.653358" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_288_161440"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_288_161440"
            result="shape"
          />
        </filter>
        <linearGradient
          id={`${uniqeIdGradient0}_linear_288_161440`}
          x1="50.0306"
          y1="17.483"
          x2="84.8674"
          y2="17.483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6499F9" />
          <stop offset="1" stopColor="#09E1FE" />
        </linearGradient>
        <linearGradient
          id={`${uniqeIdGradient1}_linear_288_161440`}
          x1="57.771"
          y1="16.1927"
          x2="20.3536"
          y2="16.8379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7888F8" />
          <stop offset="1" stopColor="#E55AD5" />
        </linearGradient>
        <linearGradient
          id={`${uniqeIdGradient2}_linear_288_161440`}
          x1="24.8696"
          y1="13.6123"
          x2="-5.45125"
          y2="13.6123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED65C8" />
          <stop offset="1" stopColor="#FF7BAC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
