import React, { useCallback, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { Skill } from "models/Skills";
import { Button, Checkbox, Modal } from "ncoded-component-library";
import { Form } from "react-final-form";
import InputField from "components/fields/InputField";
import Navigation from "components/Navigation";

import "./SkillsPage.styles.scss";

const columnHelper = createColumnHelper<Skill>();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>ID</span>,
  }),
  columnHelper.accessor("name", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Name</span>,
  }),

  columnHelper.accessor("type", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Type</span>,
  }),
  columnHelper.accessor("status", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Status</span>,
  }),
];

const SkillsPage = () => {
  const [crateNewSkillModal, setCrateNewSkillModal] = useState(false);
  const [showPendingSkills, setShowPendingSkills] = useState(false);

  const queryClient = useQueryClient();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const defaultData = React.useMemo(() => [], []);

  const getSkills = useCallback(
    async (options: { pageIndex: number; pageSize: number }) => {
      try {
        const where = [
          showPendingSkills ? { status: { EQUAL: "Pending" } } : {},
        ];

        const { data } = await api.skills.getSkills({
          $take: options.pageSize,
          $page: options.pageIndex + 1,
          $order: "id",
          $where: JSON.stringify(where),
        });
        if (data) {
          return {
            rows: data.items,
            pageCount: data.totalPages,
          };
        }
        // setData(data.items);
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    [showPendingSkills]
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const dataQuery = useQuery(
    ["skills", fetchDataOptions, showPendingSkills],
    () => getSkills(fetchDataOptions),
    { keepPreviousData: true }
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  const addSkill = useCallback(async (values: { name: string }) => {
    try {
      await api.skills.createSkill({
        name: values.name,
        type: "Uncategorized",
        status: "Active",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const mutationAddSkill = useMutation({
    mutationFn: addSkill,

    onSuccess: (data, variables, context) => {
      const optimisticTodo = { name: variables.name };

      queryClient.setQueryData(["skills", fetchDataOptions], (old: any) => {
        return {
          ...old,
          optimisticTodo,
        };
      });

      table.setPageIndex(table.getPageCount() - 1);
      setCrateNewSkillModal(false);
    },
  });

  const deleteSkill = useCallback(async (values: { id: number }) => {
    try {
      await api.skills.deleteSkill(values.id);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const mutationDeleteSkill = useMutation({
    mutationFn: deleteSkill,

    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["skills", fetchDataOptions], (old: any) => ({
        ...old,
        rows: old.rows?.filter((item: any) => item.id !== variables.id),
      }));

      setCrateNewSkillModal(false);
    },
  });

  const updateSkill = useCallback(
    async (values: { id: number; status: string }) => {
      try {
        await api.skills.updateSkill(values.id, values.status);
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const mutationUpdateSkill = useMutation({
    mutationFn: updateSkill,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["skills", fetchDataOptions], (old: any) => ({
        ...old,
        rows: old.rows?.map((item: any) => {
          if (item.id === variables.id) {
            return { ...item, status: variables.status };
          } else return item;
        }),
      }));

      setCrateNewSkillModal(false);
    },
  });

  return (
    <div>
      <Navigation />

      <div className="p-2">
        <div className="skills-page__filters">
          <Checkbox
            onChange={(checked) => {
              setShowPendingSkills(checked as boolean);
            }}
            label="Show only skills needing activation"
            checked={showPendingSkills}
          />
        </div>
        <div className="anys-dash__table">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <Button
                        variant="outline"
                        onClick={() =>
                          mutationDeleteSkill.mutate({ id: row.original.id })
                        }
                      >
                        Delete
                      </Button>

                      {row.original.status === "Pending" && (
                        <Button
                          variant="outline"
                          onClick={() =>
                            mutationUpdateSkill.mutate({
                              id: row.original.id,
                              status: "Active",
                            })
                          }
                        >
                          Activate
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="h-2" />
          <div className="flex items-center gap-2">
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="flex items-center gap-1">
              | Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="border p-1 rounded w-16"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            {dataQuery.isFetching ? "Loading..." : null}
          </div>

          <Button
            onClick={() => setCrateNewSkillModal(true)}
            className="create-skill-btn"
          >
            Create skill
          </Button>

          <Modal
            open={crateNewSkillModal}
            controlledByParent
            title="Crate New Skill"
            onClose={() => setCrateNewSkillModal(false)}
          >
            <Form
              onSubmit={(values: { name: string }) =>
                mutationAddSkill.mutate(values)
              }
              render={(formRenderProps) => {
                const { handleSubmit } = formRenderProps;
                return (
                  <form onSubmit={handleSubmit}>
                    <InputField name="name" label="Name" />
                    <Button
                      type="submit"
                      className="create-skill-btn__in-modal"
                    >
                      Create skill
                    </Button>
                  </form>
                );
              }}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SkillsPage;
