import React, { useState, useCallback, useMemo } from "react";
import { SectionValue } from "models/SectionValue";
import { Button, Modal } from "ncoded-component-library";
import DownArrow from "icons/DownArrow";
import UpArrow from "icons/UpArrow";
import api from "api";
import useHeightAnimation from "hooks/useHeightAnimation";
import DeleteIcon from "icons/Delete.icon";
import PenIcon from "icons/Pen.icon";
import { Form } from "react-final-form";
import InputField from "components/fields/InputField";
import { StaticContent } from "models/StaticContent";

import "./Question.styles.scss";


type QuestionProps = SectionValue & {
  handleDelete?: (id: number) => void;
  section: string;
};

const Question = (props: QuestionProps) => {
  const { question, answer, id, section, handleDelete } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [questionState, setQuestionState] = useState(question);
  const [answerState, setAnswerState] = useState(answer);

  const { isExpanded, toggleIsExpanded, contentRef, contentHeight } =
    useHeightAnimation(false);

  const initalValues = useMemo(
    () => ({
      question: questionState,
      answer: answerState,
    }),
    [answerState, questionState]
  );

  const handleQuestionDelete = useCallback(
    async (id: number) => {
      try {
        await api.staticPages.deleteStaticContent(id);
        handleDelete(id);
        setModalOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [handleDelete]
  );

  const handleEditQuestion = useCallback(
    async (formValues: { question: string; answer: string }) => {
      const { question, answer } = formValues;

      try {
        const submitContent = JSON.stringify(
          { heading: question, text: answer, section },
          null,
          2
        );

        const dto: StaticContent = {
          type: "faq",
          state: "shown",
          content: submitContent,
        };

        const { data } = await api.staticPages.updateStaticContent(id, dto);

        if (data) {
          const { heading, text } = JSON.parse(data.content);
          setQuestionState(heading);
          setAnswerState(text);
          setEditModalOpen(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [id, section]
  );

  return (
    <div className="anys-dash-question">
      <div className="anys-dash-question__header">
        <div
          className="anys-dash-question__header__title"
          onClick={toggleIsExpanded}
        >
          <div className="anys-dash-question__header__title__txt">
            <h3>{questionState}</h3>
            {isExpanded ? <UpArrow /> : <DownArrow />}
          </div>
        </div>

        <Button
          variant="link"
          onClick={() => setEditModalOpen(true)}
          className="anys-dash-question__header__edit"
        >
          <PenIcon />
        </Button>

        <Button
          variant="link"
          onClick={() => setModalOpen(true)}
          className="anys-dash-question__header__delete"
        >
          <DeleteIcon />
        </Button>
      </div>
      <div
        className="anys-dash-question__answer"
        style={{
          height: isExpanded ? contentHeight : 0,
          overflow: "hidden",
          transition: "height 0.5s ease",
        }}
        ref={contentRef}
      >
        <p>{answerState}</p>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        controlledByParent
        renderAsPortal
      >
        <div className="anys-dash-question__modal">
          <span className="anys-dash-question__modal__title">Are you sure you want to delete question?</span>
          <div className="anys-dash-question__modal__actions">
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button variant="outline" onClick={() => handleQuestionDelete(id)}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={editModalOpen}
        controlledByParent
        title="Edit question"
        onClose={() => setEditModalOpen(false)}
      >
        <Form
          onSubmit={handleEditQuestion}
          initialValues={initalValues}
          render={(formRenderProps: { handleSubmit: any }) => {
            const { handleSubmit } = formRenderProps;
            return (
              <div className="anys-dash-question__modal">
                <form onSubmit={handleSubmit}>
                  <InputField name="question" label="Question" autoFocus />
                  <InputField name="answer" label="Answer" />
                  <Button type="submit">Edit</Button>
                </form>
              </div>
            );
          }}
        />
      </Modal>
    </div>
  );
};

export default Question;
