import React from "react";
import { File } from "models/File";
import FileIcon from "icons/FileIcon";
import classNames from "classnames";

import "./FileItem.styles.scss";

const FileItem = (props: File) => {
  const { name, url } = props;

  const classes = classNames("anys-file-item");
  return (
    <a
      href={url}
      className={classes}
      target="_blank"
      rel="noopener noreferrer">
      <FileIcon />
      <p className="anys-file-item__name">{name}</p>
    </a>
  );
};

export default FileItem;
