import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import credentialsService from "services/credentialsService";
import env from "env";
import bus, { AuthEventTypes } from "modules/bus";

const httpClient = Axios.create({
  baseURL: `${env.SERVER_ENDPOINT}/api/`,
});

const setToken = (config: AxiosRequestConfig) => {
  const { token } = credentialsService;

  if (token && config.method !== "OPTIONS") {
    config.headers.Authorization = `Bearer ${token}`;
  }
};

httpClient.interceptors.request.use(
  function (config) {
    setToken(config);

    return config;
  },
  function (err: AxiosError) {
    Promise.reject(err);
  }
);

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login

    const { token } = credentialsService;
    if (token && config.method !== "OPTIONS") {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(null, async (error) => {
  const { response, config } = error;

  if (
    config &&
    !["api", "anyservice"].every((str) => config.baseURL.includes(str))
  ) {
    return Promise.reject(error);
  }

  if (response && 403 === response.status) {
    bus.broadcastEvent("UNAUTHORISED_SIGNAL");
    return Promise.reject(error);
  }

  if (response && [401].includes(response.status)) {
    bus.broadcastEvent("LOGOUT_SIGNAL");
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default httpClient;
