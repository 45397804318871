import credentialsService from './credentialsService';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  logout() {
    // Remove auth data that we store in storage
    storageService.removeItem(STORAGE_KEYS.EMAIL_TO_VERIFY, true);
    credentialsService.removeAuthBody();

    // Do a hard reload
    window.location.href = '/login';
  },
};
