import users from "./users";
import auth from "./auth";
import skills from "./skills";
import contracts from "./contracts";
import arbitrations from "./arbitrations";
import anyCoins from "./anyCoins";
import staticPages from "./staticPages";

export default {
  users,
  auth,
  skills,
  contracts,
  arbitrations,
  anyCoins,
  staticPages
};
