import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Button } from "ncoded-component-library";
import authService from "services/authService";

import "./Navigation.styles.scss";

type NavigationLinkProps = {
  to: string;
  label: string;
};

const NavigationLink = (props: NavigationLinkProps) => {
  const { to, label } = props;
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
    >
      {label}
    </NavLink>
  );
};

type NavigationProps = {
  className?: string;
};

const Navigation = (props: NavigationProps) => {
  const { className } = props;
  const classes = classNames("anys-navigation", className);

  return (
    <nav className={classes}>
      <div className="anys-navigation__links">
        <NavigationLink to="/users" label="Users" />
        <NavigationLink to="/reports" label="Reports" />
        <NavigationLink to="/skills" label="Skills" />
        <NavigationLink to="/arbitrations" label="Arbitrations" />
        <NavigationLink to="/any-coins" label="Any Coins" />
        <NavigationLink to="/static-pages" label="Static pages" />
      </div>
      <Button
        variant="outline"
        onClick={() => {
          authService.logout();
        }}
      >
        Log out
      </Button>
    </nav>
  );
};

export default Navigation;
