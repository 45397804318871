import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import api from "api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CalendarDropdown from "components/CalendarDropdown";
import dayjs from "dayjs";
import Navigation from "components/Navigation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

type ReportPageProps = {
  className?: string;
};

type AnyCoinsStatsType = {
  date: string;
  revenueFromAnyToDollarsTrade: number;
  numberOfSignupEarnedAnyCoins: number;
  numberOfReferralEarnedAnyCoins: number;
  numberOfSpentSignupEarnedAnyCoins: number;
  numberOfSpentReferralEarnedAnyCoins: number;
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
    },
  },
};

const AnysPage = (props: ReportPageProps) => {
  const { className } = props;
  const classes = classNames("anys-report-page", className);

  const [anycoinsStats, setAnycoinsStats] = useState<AnyCoinsStatsType[]>([]);

  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "day"),
    end_date: dayjs(),
  });

  const getAnyCoins = useCallback(async () => {
    try {
      const queryParams = {
        fromDate: dateFilter.start_date.format("YYYY-MM-DD"),
        toDate: dateFilter.end_date.format("YYYY-MM-DD"),
      };

      const { data } = await api.anyCoins.getAnyCoins(queryParams);

      if (data) {
        setAnycoinsStats(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dateFilter.end_date, dateFilter.start_date]);

  const labels = useMemo(
    () =>
      anycoinsStats.length > 0
        ? anycoinsStats.map((anycoinsStat) => anycoinsStat.date)
        : [],
    [anycoinsStats]
  );

  const revenue = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: false,
          label: "Revenue from trade",
          data: anycoinsStats.map(
            (anycoinsStat) => anycoinsStat.revenueFromAnyToDollarsTrade || 0
          ),
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [anycoinsStats, labels]
  );

  const givenAnys = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: false,
          label: "Anys spent from refferal",
          data: anycoinsStats.map(
            (anycoinsStat) => anycoinsStat.numberOfSignupEarnedAnyCoins || 0
          ),
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          fill: false,
          label: "Anys spent from registration",
          data: anycoinsStats.map(
            (anycoinsStat) =>
              anycoinsStat.numberOfSpentSignupEarnedAnyCoins || 0
          ),
          borderColor: "red",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [anycoinsStats, labels]
  );

  const spentAnys = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: false,
          label: "Registration spent anys",
          data: anycoinsStats.map(
            (anycoinsStat) =>
              anycoinsStat.numberOfSpentSignupEarnedAnyCoins || 0
          ),
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          fill: false,
          label: "Referral spent anys",
          data: anycoinsStats.map(
            (anycoinsStat) =>
              anycoinsStat.numberOfSpentReferralEarnedAnyCoins || 0
          ),
          borderColor: "red",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [anycoinsStats, labels]
  );

  useEffect(() => {
    getAnyCoins();
  }, [getAnyCoins]);

  return (
    <div className={classes}>
      <Navigation />
      <div className="anys-report-page__filter">
        <div className="anys-report-page__filter__dates">
          <CalendarDropdown
            value={dateFilter.start_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, start_date: value }));
            }}
            label="Start date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs().subtract(1, "day")}
          />

          <CalendarDropdown
            value={dateFilter.end_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, end_date: value }));
            }}
            label="End date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs()}
          />
        </div>
      </div>

      <div className="anys-report-page__charts">
        <div>
          <Line options={options} data={revenue} />
        </div>
        <div>
          <Line options={options} data={givenAnys} />
        </div>

        <div>
          <Line options={options} data={spentAnys} />
        </div>
      </div>
    </div>
  );
};

export default AnysPage;
