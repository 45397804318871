import storageService, { STORAGE_KEYS } from './storageService';

export type AuthResBody = {
  token: string;
  // refreshToken: string;
  // user: User;
};

export default {
  get token() {
    return storageService.getItem(STORAGE_KEYS.TOKEN);
  },

  set token(value: string) {
    storageService.setItem(STORAGE_KEYS.TOKEN, value);
  },

  get refreshToken(): string {
    return storageService.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  },

  set refreshToken(value: string) {
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, value);
  },

  saveAuthBody({ token }: Omit<AuthResBody, 'user'>) {
    this.token = token;
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },
};
