import React, { useState, useCallback, useEffect } from "react";
import QuestionsSection from "components/Section/QuestionsSection.component";
import { Button, Modal } from "ncoded-component-library";
import InputField from "components/fields/InputField";
import { SectionValue } from "models/SectionValue";
import Navigation from "components/Navigation";
import { Form } from "react-final-form";
import api from "api";

import "./StaticPages.styles.scss";

const StaticPages = () => {
  const [sectionName, setSectionName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [sections, setSections] = useState<Map<string, SectionValue[]>>(
    new Map<string, SectionValue[]>()
  );

  const getStaticContent = useCallback(async () => {
    try {
      const { data: questionList } = await api.staticPages.getStaticContent();

      if (questionList) {
        const sectionsData: Map<string, SectionValue[]> = new Map<
          string,
          SectionValue[]
        >();

        questionList.items.forEach((item: any) => {
          const { heading, section, text } = JSON.parse(item.content);

          if (!sectionsData.get(section)) {
            sectionsData.set(section, []);
          }

          sectionsData.get(section).push({
            id: item.id,
            question: heading,
            answer: text,
          });
        });
        setSections(sectionsData);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleSectionDelete = useCallback(
    (sectionName: string) => {
      const updatedSections = new Map(sections);
      updatedSections.delete(sectionName);
      setSections(updatedSections);
    },
    [sections]
  );

  const handleSectionPost = useCallback(
    (value: SectionValue, sectionName: string) => {
      const updatedSections = new Map(sections);
      updatedSections.get(sectionName).push(value);
      setSections(updatedSections);
    },
    [sections]
  );

  const handleOnClick = useCallback(() => {
    setSections((prevSections) => prevSections.set(sectionName, []));
    setSectionName("");
    setModalOpen(false);
  }, [sectionName]);

  const validate = (value: string) => {
    if (!value) return "Required";
    if (sections.has(value)) return "Section already exists";

    return undefined;
  };
  useEffect(() => {
    getStaticContent();
  }, [getStaticContent]);

  return (
    <div className="anys-dash-static-pages">
      <Navigation />
      <div className="anys-dash-static-pages__content">
        <div className="anys-dash-static-pages__content__title">
          <h1>FAQ</h1>

          <Button onClick={() => setModalOpen(true)}>Add section</Button>
        </div>
        <div className="anys-dash-static-pages__content__sections">
          {sections &&
            Array.from(sections.keys()).map((section, index) => {
              return (
                <QuestionsSection
                  key={index}
                  name={section}
                  content={sections.get(section)}
                  deleteSection={handleSectionDelete}
                  addSection={handleSectionPost}
                />
              );
            })}
        </div>

        <Modal
          open={modalOpen}
          controlledByParent
          title="Add new section"
          onClose={() => setModalOpen(false)}
        >
          <Form
            onSubmit={handleOnClick}
            render={(formRenderProps: { handleSubmit: any }) => {
              const { handleSubmit } = formRenderProps;
              return (
                <div className="anys-dash-question__modal">
                  <form onSubmit={handleSubmit}>
                    <InputField
                      name="question"
                      value={sectionName}
                      onChange={(e) => setSectionName(e.target.value)}
                      label="Section name"
                      autoFocus
                      validate={validate}
                    />

                    <Button type="submit">Add question</Button>
                  </form>
                </div>
              );
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default StaticPages;
