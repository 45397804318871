import httpClient from "api/httpClient";

export type LoginParams = {
  email: string;
  password: string;
};

const login = (loginData: LoginParams) =>
  httpClient.post<{ token: string }>("admin/auth/login", loginData);

export default { login };
