import { Fragment, useEffect } from "react";
import Router from "./router/Router";
import { Wrapper } from "@googlemaps/react-wrapper";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./App.css";
import bus, { AuthEventTypes } from "modules/bus";
import authService from "services/authService";

export const GOOGLE_MAPS_PLACES_SCRIPT_ID = "google_maps_places_script";

function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    const logoutListener = bus.addEventListener(
      AuthEventTypes.LOGOUT_SIGNAL,
      authService.logout
    );

    return () => {
      logoutListener();
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        id={GOOGLE_MAPS_PLACES_SCRIPT_ID}
        libraries={["places"]}
      >
        <Fragment>
          <Router />
        </Fragment>
      </Wrapper>
    </QueryClientProvider>
  );
}

export default App;
