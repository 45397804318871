import React, { useState, useCallback, useEffect } from "react";
import Question from "components/SectionQuestion/Question.component";
import { Button, Modal } from "ncoded-component-library";
import InputField from "components/fields/InputField";
import { StaticContent } from "models/StaticContent";
import { SectionValue } from "models/SectionValue";
import { Form } from "react-final-form";
import api from "api";
import AddSmallIcon from "icons/AddSmall.icon";

import "./QuestionsSection.styles.scss";

type SectionProps = {
  name?: string;
  content?: SectionValue[];
  deleteSection?: (value: string) => void;
  addSection?: (value: SectionValue, sectionName: string) => void;
};

const QuestionsSection = (props: SectionProps) => {
  const { name, content, deleteSection, addSection } = props;

  const [contents, setContents] = useState<SectionValue[]>(content);
  const [modalOpen, setModalOpen] = useState(false);

  const handleQuestionDelete = useCallback(
    (id: number) => {
      const updatedContent = contents.filter((item) => item.id !== id);
      setContents(updatedContent);
      if (updatedContent.length === 0) {
        deleteSection(name);
      }
    },
    [contents, deleteSection, name]
  );

  useEffect(() => {
    setContents(content);
  }, [content]);

  const onSubmit = useCallback(
    async (formValues: { question: string; answer: string }) => {
      const { question, answer } = formValues;

      try {
        const submitContent = JSON.stringify(
          { heading: question, text: answer, section: name },
          null,
          2
        );

        const dto: StaticContent = {
          type: "faq",
          state: "shown",
          content: submitContent,
        };

        const { data } = await api.staticPages.createStaticContent(dto);
        if (data) {
          const { heading, text } = JSON.parse(data.content);
          setContents((prevContents) => [
            ...prevContents,
            { id: data.id, question: heading, answer: text },
          ]);
          addSection({ id: data.id, question: heading, answer: text }, name);
        }

        setModalOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [addSection, name]
  );

  const required = (value: string) => {
    return value ? undefined : "Required";
  };

  return (
    <div className="anys-dash-questions-section">
      <div className="anys-dash-questions-section__title">
        <h2>{name}</h2>

        <Button type="button" variant="link" onClick={() => setModalOpen(true)}>
          <AddSmallIcon />
        </Button>
      </div>

      <Modal
        open={modalOpen}
        controlledByParent
        title="Add new question"
        onClose={() => setModalOpen(false)}
      >
        <Form
          onSubmit={onSubmit}
          render={(formRenderProps: { handleSubmit: any }) => {
            const { handleSubmit } = formRenderProps;
            return (
              <div className="anys-dash-question__modal">
                <form onSubmit={handleSubmit}>
                  <InputField
                    name="question"
                    label="Question"
                    autoFocus
                    validate={required}
                  />
                  <InputField
                    name="answer"
                    label="Answer"
                    validate={required}
                  />
                  <Button type="submit">Add question</Button>
                </form>
              </div>
            );
          }}
        />
      </Modal>

      {contents?.map(({ question, answer, id }) => (
        <Question
          key={id}
          handleDelete={handleQuestionDelete}
          question={question}
          answer={answer}
          section={name}
          id={id}
        />
      ))}
    </div>
  );
};

export default QuestionsSection;
