import { GOOGLE_MAPS_PLACES_SCRIPT_ID } from "App";
// import OverlaySpinner from 'components/OverlaySpinner';
import { useCallback, useEffect, useState } from "react";
import useEffectOnce from "./useEffectOnce";

const LOADER_EL_NAME = "#root";

export type LocationType = {
  id?: number;
  city: string;
  country: string;
  area?: number;
  lat: number;
  lng: number;
};

export type PlaceFormat = Omit<LocationType, "id" | "area">;

export type CurrentLocationState =
  | "not-allowed"
  | "partial"
  | "full"
  | "loading";

type CurrentUserRet = {
  getCurrentLocation: () => void;
} & Readonly<
  | {
      state: "not-allowed" | "loading";
      location: undefined;
    }
  | {
      state: "partial";
      location: Pick<PlaceFormat, "lat" | "lng">;
    }
  | {
      state: "full";
      location: Required<PlaceFormat>;
    }
>;

const GEOCODED_COORDS: Record<string, { city: string; country: string }> = {};

export const getLatLngKey = (lat: number, lng: number) => `${lat}:${lng}`;

type CurrentLocationProps = Partial<{
  callInitially: boolean;
  onFinished: (position?: PlaceFormat) => void;
}>;

const useCurrentLocation = (props?: CurrentLocationProps): CurrentUserRet => {
  const { callInitially = true, onFinished } = props || {};

  const [state, setState] = useState<CurrentLocationState>("loading");

  const [location, setLocation] = useState<Partial<PlaceFormat>>();

  const [placesScript, setPlacesScript] = useState<any & HTMLScriptElement>();

  const onLocationDisabledInternal = useCallback(() => {
    setState("not-allowed");
    // OverlaySpinner.hide(LOADER_EL_NAME);
  }, []);

  const geocodeLocation = useCallback(
    (lat: number, lng: number) => {
      if (placesScript && lat && lng) {
        const cachedCoords = GEOCODED_COORDS[getLatLngKey(lat, lng)];
        if (cachedCoords) {
          return cachedCoords;
        }

        // OverlaySpinner.show(LOADER_EL_NAME);

        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const city = results[0].address_components.find(
              (address) =>
                address.types.includes("locality") ||
                address.types.includes("city")
            ).long_name;

            const country = results[0].address_components.find((address) =>
              address.types.includes("country")
            ).long_name;

            setLocation((old) => ({ ...old, city, country }));
            setState("full");
          }
          // OverlaySpinner.hide(LOADER_EL_NAME);
        });
      }
    },
    [placesScript]
  );

  const onLocationAllowedInternal: PositionCallback = useCallback(
    ({ coords: { latitude, longitude } }) => {
      setLocation({ lat: latitude, lng: longitude });
      setState("partial");
      // OverlaySpinner.hide(LOADER_EL_NAME);
    },
    []
  );

  const getCurrentLocation = useCallback(() => {
    // OverlaySpinner.show(LOADER_EL_NAME);

    window.navigator.geolocation.getCurrentPosition(
      onLocationAllowedInternal,
      onLocationDisabledInternal,
      {
        maximumAge: 120000,
      }
    );
  }, [onLocationAllowedInternal, onLocationDisabledInternal]);

  useEffect(() => {
    if (!placesScript) {
      const script = document.getElementById(GOOGLE_MAPS_PLACES_SCRIPT_ID);
      if (script) {
        setPlacesScript(script);
      }
    }
  }, [placesScript]);

  useEffectOnce(() => {
    if (!callInitially) return;

    getCurrentLocation();
  });

  useEffect(() => {
    if (state !== "partial" || !placesScript) return;

    geocodeLocation(location.lat, location.lng);
  }, [geocodeLocation, location, placesScript, state]);

  useEffect(() => {
    if (state === "full") {
      onFinished?.(location as PlaceFormat);
    } else if (state === "not-allowed") {
      onFinished?.(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, state]);

  return { location, state, getCurrentLocation } as const as CurrentUserRet;
};

export default useCurrentLocation;
