import httpClient from "api/httpClient";
import { StaticContent } from "models/StaticContent";

const getStaticContent = () => {
  return httpClient.get("admin/static-contents");
};

const updateStaticContent = (id: number, body: Partial<StaticContent>) => {
  return httpClient.put(`admin/static-contents/${id}`, body);
};

const createStaticContent = (params: Partial<StaticContent>) => {
  return httpClient.post("admin/static-contents", params);
};

const deleteStaticContent = (id: number) => {
  return httpClient.delete(`admin/static-contents/${id}`);
};

export default {
  getStaticContent,
  updateStaticContent,
  createStaticContent,
  deleteStaticContent,
};
