import React, { useCallback } from "react";
import classNames from "classnames";
import { Input as InputNcLib } from "ncoded-component-library";
import ValidationError from "../ValidationError";

import "./Input.styles.scss";

const SPECIAL_NUMBER_CHARS = ["e", "E", "+", "-"];

export type ExtractPropTypes<Type> = Type extends React.FC<infer P> ? P : never;

export type DefaultInputProps = ExtractPropTypes<typeof InputNcLib>;

export type InputProps = {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: React.ReactNode;
  labelDescription?: string;
  isLargeLabel?: boolean;
  name: string;
  inputDescription?: React.ReactNode;
  error?: string;
  hideNumberArrows?: boolean;
  isPureNumberInput?: boolean;
  required?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
} & DefaultInputProps;

const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    labelClassName,
    inputClassName,
    label,
    labelDescription,
    isLargeLabel,
    name,
    inputDescription,
    hasError,
    error,
    type,
    hideNumberArrows = true,
    isPureNumberInput = false,
    required = false,
    onKeyDown,
    inputRef,
    ...rest
  } = props;

  const isNumberInput = type === "number";

  const classes = classNames("anys-input", className);

  const labelClasses = classNames(
    "anys-input__label",
    { "anys-input__label--large": isLargeLabel },
    labelClassName
  );
  const labelDescClasses = classNames("anys-input__label__description", {
    "anys-input__label__description--large-spacing": isLargeLabel,
  });

  const inputClasses = classNames(
    "anys-input__input",
    {
      "anys-input__input--no-arrows": isNumberInput && hideNumberArrows,
    },
    inputClassName
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isPureNumberInput && SPECIAL_NUMBER_CHARS.includes(event.key)) {
        event.preventDefault();

        return;
      }

      onKeyDown?.(event);
    },
    [isPureNumberInput, onKeyDown]
  );

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          <span>
            {label}
            {required && (
              <span className="anys-input__label__required">{"*"}</span>
            )}
          </span>

          {labelDescription && (
            <span className={labelDescClasses}>{labelDescription}</span>
          )}
        </label>
      )}

      <InputNcLib
        {...rest}
        onKeyDown={handleKeyDown}
        type={type}
        hasError={hasError}
        name={name}
        className={inputClasses}
        required={required}
        ref={inputRef}
      />

      {hasError && <ValidationError showError={hasError} error={error} />}

      {!!inputDescription && (
        <span className="anys-input__description">{inputDescription}</span>
      )}
    </div>
  );
};

export default Input;
