import React, { useEffect, useMemo, useRef } from "react";
import { RouteProps, Navigate, useLocation } from "react-router-dom";
import credentialsService from "services/credentialsService";

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
} & Omit<RouteProps, "element"> & {
    element: React.FC | React.ReactNode;
  };

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const { authorized = false, onlyPublic = false, element } = props;

  const hasToken = !!credentialsService.token;
  const location = useLocation();
  const prevPathname = useRef(location.pathname);

  const finalRoute = useMemo(() => {
    if (authorized && !hasToken) {
      return (
        <Navigate
          to={{ pathname: "/login" }}
          state={{ from: location.pathname }}
          replace
        />
      );
    }

    if (onlyPublic && hasToken) {
      return <Navigate to="/" />;
    }

    if (typeof element === "function") {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [authorized, hasToken, onlyPublic, location, element]);

  useEffect(() => {
    if (prevPathname.current !== location.pathname) {
      window.scroll({
        top: 0,
        left: 0,
      });

      prevPathname.current = location.pathname;
    }
  }, [location.pathname]);

  return finalRoute;
};

export default EnhancedRoute;
