import httpClient from "api/httpClient";
import { PaginationParams } from "models/Pagination";
import { Skill } from "models/Skills";

const getSkills = (params: PaginationParams) =>
  httpClient.get("admin/skills", { params });

const createSkill = (params: Partial<Skill>) =>
  httpClient.post("admin/skills", params);

const deleteSkill = (id: number) => httpClient.delete(`admin/skills/${id}`);

const updateSkill = (id: number, status: string) =>
  httpClient.put(`admin/skills/${id}`, { status });

const getAcceptedSkills = () => httpClient.get(`skills`);

export default {
  getSkills,
  createSkill,
  deleteSkill,
  updateSkill,
  getAcceptedSkills,
};
