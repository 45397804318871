import React, { useCallback } from 'react';
import classNames from 'classnames';
import Input from 'components/Input/Input.component';

import './InputAutocompleteLocation.styles.scss';
import useLocationAutocomplete from './useLocationAutocomplete';
import { PlaceFormat } from 'hooks/useCurrentLocation';

export type InputAutocompleteLocationProps = {
  className?: string;
  name: string;
  value: PlaceFormat;
  useProfileLocation?: boolean;
  locationPromptInitially?: boolean;
  onChange?: (value: PlaceFormat) => void;
} & React.ComponentProps<typeof Input>;

const InputAutocompleteLocation: React.FC<
  React.PropsWithChildren<InputAutocompleteLocationProps>
> = (props) => {
  const {
    className,
    name,
    value,
    useProfileLocation,
    locationPromptInitially = false,
    onChange,
    onBlur,
    onFocus,
    ...rest
  } = props;

  const classes = classNames('anys-input-autocomplete-location', className);

  const {
    query,
    autoCompleteInputRef,
    onInputChange,
    onInputBlur,
    onInputFocus,
  } = useLocationAutocomplete({
    value,
    useProfileLocation,
    locationPromptInitially,
    onPlaceSelect: onChange,
  });

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);

      onInputBlur();
    },
    [onBlur, onInputBlur],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);

      onInputFocus();
    },
    [onFocus, onInputFocus],
  );

  return (
    <Input
      {...rest}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      inputRef={autoCompleteInputRef}
      onChange={onInputChange}
      autoComplete="off"
      value={query}
      className={classes}
    />
  );
};

export default InputAutocompleteLocation;
