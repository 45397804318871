import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import "./ReportPage.styles.scss";
import api from "api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CalendarDropdown from "components/CalendarDropdown";
import dayjs from "dayjs";
import { Select } from "ncoded-component-library";
import { Skill } from "models/Skills";
import InputAutocompleteLocation from "components/InputAutocompleteLocation";
import Navigation from "components/Navigation";
import { formatValueCurrency } from "utils/currency";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

type ReportPageProps = {
  className?: string;
};

type ContractStatsType = {
  date: string;
  estimatedRevenueMax: number;
  estimatedRevenueMin: number;
  numberOfStartedContracts: number;
  numberOfFinishedContracts?: number;
  revenue?: number;
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
    },
  },
};

const ReportPage = (props: ReportPageProps) => {
  const { className } = props;
  const classes = classNames("anys-report-page", className);

  const [contractStats, setContractStats] = useState<ContractStatsType[]>([]);

  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "day"),
    end_date: dayjs(),
  });

  const [skillFilterOptions, setSkillFilterOptions] = useState([]);
  const [skillFilter, setSkillFilter] = useState();

  const [locationFilter, setLocationFilter] = useState();

  const getContracts = useCallback(async () => {
    try {
      // const { data } = await api.contracts.getContractsStats({
      //   skills: skillFilter ? [(skillFilter as any).value] : undefined,
      //   fromDate: dateFilter.start_date.format("YYYY-MM-DD"),
      //   toDate: dateFilter.end_date.format("YYYY-MM-DD"),
      // });

      // const queryParams: PaginationParams = {
      //   $where: where ? JSON.stringify(where) : null,
      //   $take: take,
      //   $skip: (currentPage - 1) * take,
      //   $order: '-createdAt',
      //   $relations:
      //     'mainSkill, skills, boosts, user.profileImage, user.overallClientScore, timeAndPricing, typeOfService',
      //   $currentLocation,
      // };

      // [
      //   {
      //     "typeOfService.locationType": { EQUAL: "One spot" },
      //     "typeOfService.coordinates": { EQUAL: "43.320926,21.8954069" },
      //     type: { EQUAL: "Provide" },
      //   },
      // ];

      const where = {
        "skills.id":
          skillFilter && `{ "In": [${(skillFilter as any).value.toString()}] }`,
        "typeOfService.coordinates":
          (locationFilter as any)?.lat &&
          (locationFilter as any)?.lng &&
          `{ "EQUAL": "${(locationFilter as any).lat},${
            (locationFilter as any).lng
          }" }`,
      };

      const queryParams = {
        $where:
          JSON.stringify(where) !== "{}" ? JSON.stringify(where) : undefined,
        $order: "fromDate",
        fromDate: dateFilter.start_date.format("YYYY-MM-DD"),
        toDate: dateFilter.end_date.format("YYYY-MM-DD"),
      };

      const { data } = await api.contracts.getContractsStats(queryParams);

      if (data) {
        setContractStats(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dateFilter.end_date, dateFilter.start_date, locationFilter, skillFilter]);

  const labels = useMemo(
    () =>
      contractStats.length > 0
        ? contractStats.map((contractStat) => contractStat.date)
        : [],
    [contractStats]
  );

  const activeContractsData = useMemo(
    () =>
      contractStats.map(
        (contractStat) => contractStat.numberOfStartedContracts || 0
      ),
    [contractStats]
  );

  const activeContracts = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          label: "Started contracts",
          data: activeContractsData,
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [activeContractsData, labels]
  );

  // REVENUE ide za DONE contracts
  // Potential revenue ide za active contracts

  const doneContractsStats = useMemo(
    () =>
      contractStats.map(
        (contractStat) => contractStat.numberOfFinishedContracts || 0
      ),
    [contractStats]
  );

  const doneContractsRevenue = useMemo(
    () =>
      contractStats.map((contractStat) => (contractStat.revenue || 0) / 100),
    [contractStats]
  );

  const doneContracts = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          label: "Done contracts",
          data: doneContractsStats,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          fill: true,
          label: "Revenue",
          data: doneContractsRevenue,
          borderColor: "red",
          // backgroundColor: "red",
        },
      ],
    }),
    [doneContractsRevenue, doneContractsStats, labels]
  );

  const estimatedRevenueMaxData = useMemo(
    () =>
      contractStats.map(
        (contractStat) => (contractStat.estimatedRevenueMax || 0) / 100
      ),
    [contractStats]
  );

  const estimatedRevenueMinData = useMemo(
    () =>
      contractStats.map(
        (contractStat) => (contractStat.estimatedRevenueMin || 0) / 100
      ),
    [contractStats]
  );

  const estimatedRevenue = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          label: "Estimated Revenue Max",
          data: estimatedRevenueMaxData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          fill: true,
          label: "Estimated Revenue Min",
          data: estimatedRevenueMinData,
          borderColor: "red",
          // backgroundColor: "red",
        },
      ],
    }),
    [estimatedRevenueMaxData, estimatedRevenueMinData, labels]
  );

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const getSkills = useCallback(async () => {
    try {
      const { data } = await api.skills.getAcceptedSkills();
      if (data) {
        setSkillFilterOptions(
          data.skills.map((skill: Skill) => ({
            value: skill.id,
            label: skill.name,
          }))
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getSkills();
  }, [getSkills]);

  return (
    <div className={classes}>
      <Navigation />

      <div className="anys-report-page__filter">
        <div className="anys-report-page__filter__dates">
          <CalendarDropdown
            value={dateFilter.start_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, start_date: value }));
            }}
            label="Start date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs().subtract(1, "day")}
          />

          <CalendarDropdown
            value={dateFilter.end_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, end_date: value }));
            }}
            label="End date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs()}
          />
        </div>

        <div className="anys-report-page__filter__skill-loc">
          <div className="anys-report-page__filter__skill">
            <label className="anys-input__label">
              <span>Filter by skill:</span>
            </label>
            <Select
              options={skillFilterOptions}
              searchable
              onChange={(value: any) => setSkillFilter(value)}
              value={skillFilter}
              innerLabel="Skills"
            />
          </div>

          <InputAutocompleteLocation
            name="locationFilter"
            value={locationFilter}
            onChange={(value) => {
              setLocationFilter(value as any);
            }}
            label="Filter by location:"
            className="anys-report-page__filter__location"
          />
        </div>
      </div>
      <div className="anys-report-page__charts">
        <div>
          <div className="anys-report-page__charts__total">
            <b>
              Total:{" "}
              {activeContractsData?.reduce((total, stat) => (total += stat), 0)}
            </b>
          </div>
          <Line options={options} data={activeContracts} />
        </div>
        <div>
          <div className="anys-report-page__charts__total">
            <b>
              Total Max:{" "}
              {formatValueCurrency(
                estimatedRevenueMaxData?.reduce(
                  (total, stat) => (total += stat),
                  0
                )
              )}
            </b>
            <br />
            <b>
              Total Min:{" "}
              {formatValueCurrency(
                estimatedRevenueMinData?.reduce(
                  (total, stat) => (total += stat),
                  0
                )
              )}
            </b>
          </div>
          <Line options={options} data={estimatedRevenue} />
        </div>
        <div>
          <div className="anys-report-page__charts__total">
            <b>
              Total Done Contracts:{" "}
              {doneContractsStats?.reduce((total, stat) => (total += stat), 0)}
            </b>
            <br />
            <b>
              Total Revenue:{" "}
              {formatValueCurrency(
                doneContractsRevenue?.reduce(
                  (total, stat) => (total += stat),
                  0
                )
              )}
            </b>
          </div>
          <Line options={options} data={doneContracts} />
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
