import React from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import AnyServiceLogoIcon from "../../icons/AnyServiceLogo.icon";
import LoginForm from "./components/LoginForm/LoginForm.component";
import { LoginParams } from "api/auth";
import api from "api";
import credentialsService from "services/credentialsService";

import "./Login.styles.scss";

type LoginProps = {
  className?: string;
};
const Login = (props: LoginProps) => {
  const { className } = props;
  const classes = classNames("anys-login", className);

  const navigate = useNavigate();

  const login = async (values: LoginParams) => {
    try {
      const { data } = await api.auth.login(values);
      if (data.token) {
        credentialsService.token = data.token;
      }
    } catch (e) {
      console.error(e);
    }
    navigate("/users");
  };

  return (
    <div className={classes}>
      <AnyServiceLogoIcon className="anys-login__logo" />

      <h2 className="anys-login__title">Login</h2>

      <LoginForm onSubmit={login} />
    </div>
  );
};

export default Login;
