import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import "./Home.styles.scss";
import Navigation from "components/Navigation";

type HomePageProps = {
  className?: string;
};
const HomePage = (props: HomePageProps) => {
  const { className } = props;
  const classes = classNames("anys-home-page", className);

  return (
    <div className={classes}>
      <Navigation />
    </div>
  );
};

export default HomePage;
