import React, { useCallback, useEffect, useState } from "react";
import Navigation from "components/Navigation";
import { useParams } from "react-router-dom";
import api from "api";
import FilesSection from "./components/FilesSection";
import {
  AnyDocument,
  Certificate,
  Education,
  Language,
} from "models/ArbitratorTerms";
import classNames from "classnames";
import { User } from "models/User";
import VerifyIcon from "icons/Verify.icon";

import "./UserArbitrationPage.styles.scss";

const UserArbitrationPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState<User>();

  const {
    role,
    businessName,
    firstName,
    lastName,
    hasVerifiedBusiness,
    hasVerifiedIdentity,
    businessDocs,
    id: userId,
  } = user || {};

  const isBusinessUser = role === "Business";

  const documents = user?.arbitratorTerms?.documents?.filter(
    (doc: AnyDocument) =>
      doc?.files?.length > 0 && doc.state === "pending-verification",
  );
  const languages = user?.arbitratorTerms?.languages.filter(
    (lang: Language) =>
      lang?.files?.length > 0 && lang.state === "pending-verification",
  );
  const educations = user?.arbitratorTerms?.educations.filter(
    (ed: Education) =>
      ed?.files?.length > 0 && ed.state === "pending-verification",
  );
  const certificates = user?.arbitratorTerms?.certificates.filter(
    (cert: Certificate) =>
      cert?.files?.length > 0 && cert.state === "pending-verification",
  );

  const classes = classNames(["anys-user-arbitration-page"]);

  const isVerified = hasVerifiedBusiness || hasVerifiedIdentity;

  const getUser = useCallback(async () => {
    try {
      const { data } = await api.users.getUser(parseInt(id), {
        $relations: "businessDocs",
      });
      setUser(data);
    } catch {
      console.error("Error");
    }
  }, [id]);

  const handleVerification = () => {
    getUser();
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className={classes}>
      <Navigation />
      <div className="anys-user-arbitration-page__content">
        <div className="anys-user-arbitration-page__title">
          <h1>{isBusinessUser ? "Business user" : "Individual user"}</h1>
        </div>

        {isVerified && (
          <div className="anys-user-arbitration-page__verified">
            <VerifyIcon /> Verified
          </div>
        )}

        <h2>
          {isBusinessUser ? (
            <div>{`Business name: ${businessName || "Not set"}`}</div>
          ) : (
            <div>
              {`First name: ${firstName || "Not set"}`}
              <br />
              {`Last name: ${lastName || "Not set"}`}
            </div>
          )}
        </h2>

        <div className="anys-user-arbitration-page__content__document-verification">
          {isBusinessUser ? (
            <FilesSection
              categoryArray={businessDocs}
              arbitratorTermsId={user?.arbitratorTerms?.id}
              category="Business documents"
              handleVerification={handleVerification}
              userId={userId}
              isVerified={hasVerifiedBusiness}
            />
          ) : (
            <>
              {languages?.length > 0 && (
                <FilesSection
                  categoryArray={languages}
                  arbitratorTermsId={user?.arbitratorTerms?.id}
                  category="Languages"
                  handleVerification={handleVerification}
                />
              )}
              {certificates?.length > 0 && (
                <FilesSection
                  categoryArray={certificates}
                  arbitratorTermsId={user?.arbitratorTerms?.id}
                  category="Certificates"
                  handleVerification={handleVerification}
                />
              )}
              {educations?.length > 0 && (
                <FilesSection
                  categoryArray={educations}
                  arbitratorTermsId={user?.arbitratorTerms?.id}
                  category="Educations"
                  handleVerification={handleVerification}
                />
              )}
              {documents?.length > 0 && (
                <FilesSection
                  categoryArray={documents}
                  arbitratorTermsId={user?.arbitratorTerms?.id}
                  category="Any documents"
                  handleVerification={handleVerification}
                />
              )}
              {educations?.length === 0 &&
                languages?.length === 0 &&
                documents?.length === 0 &&
                certificates?.length === 0 && (
                  <h1>There are no files for this user</h1>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserArbitrationPage;
