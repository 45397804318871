import AnyCoinsPage from "pages/AnyCoinsPage";
import ArbitrationsPage from "pages/ArbitrationsPage";
import HomePage from "pages/Home/Home.page";
import Login from "pages/Login";
import ReportPage from "pages/ReportPage";
import SkillsPage from "pages/SkillsPage";
import UsersPage from "pages/UsersPage";
import { ComplexRoute } from "./components/Routes/Routes";
import StaticPages from "pages/StaticPages";
import UserArbitrationPage from "pages/UserArbitrationPage";

export default [
  {
    path: "login",
    authorized: false,
    onlyPublic: true,
    element: Login,
  },
  {
    path: "dashboard",
    authorized: true,
    element: HomePage,
  },
  {
    path: "reports",
    authorized: true,
    element: ReportPage,
  },
  {
    path: "users",
    authorized: true,
    element: UsersPage,
  },
  {
    path: "users/:id",
    authorized: true,
    element: UserArbitrationPage,
  },
  {
    path: "skills",
    authorized: true,
    element: SkillsPage,
  },
  {
    path: "arbitrations",
    authorized: true,
    element: ArbitrationsPage,
  },
  {
    path: "any-coins",
    authorized: true,
    element: AnyCoinsPage,
  },
  {
    path: "static-pages",
    authorized: true,
    element: StaticPages,
  },
  {
    path: "",
    element: Login,
  },
] as Array<ComplexRoute>;
