import httpClient from "api/httpClient";
import { VerificationBody } from "models/ArbitratorTerms";
import { PaginatedResponse, PaginationParams } from "models/Pagination";
import { User } from "models/User";

const getUsers = (params: PaginationParams) =>
  httpClient.get<PaginatedResponse<User>>("admin/users", {
    params,
  });

const getUser = (id: number, params?: PaginationParams) =>
  httpClient.get(`admin/users/${id}`, { params });
const banUser = (id: number) => httpClient.post(`admin/users/${id}/ban`);

const unbanUser = (id: number) => httpClient.post(`admin/users/${id}/unban`);

const suspendUser = (id: number, date: string) =>
  httpClient.post(`admin/users/${id}/suspend`, { suspendedUntil: date });

const unsuspendUser = (id: number) =>
  httpClient.post(`admin/users/${id}/unsuspend`);

const verifyDocument = (arbitratorTermsId: number, body: VerificationBody) => {
  return httpClient.post(
    `admin/arbitrator-terms/${arbitratorTermsId}/verify`,
    body
  );
};

const verifyUserBusiness = (userId: number) => {
  return httpClient.post<{ id: number; hasVerifiedBusiness: boolean }>(
    `admin/users/${userId}/verify-business`
  );
};

export default {
  getUsers,
  getUser,
  banUser,
  unbanUser,
  suspendUser,
  unsuspendUser,
  verifyDocument,
  verifyUserBusiness,
};
