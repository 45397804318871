import React, { useCallback, useState } from "react";
import api from "api";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Checkbox, Input, Modal } from "ncoded-component-library";
import Navigation from "components/Navigation";
import CalendarDropdown from "components/CalendarDropdown";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { User } from "models/User";

import "./UsersPage.styles.scss";

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>ID</span>,
  }),
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>First Name</span>,
  }),
  columnHelper.accessor((row) => row.lastName, {
    id: "lastName",
    cell: (info) => info.getValue() || "-",
    header: () => <span>Last Name</span>,
  }),
  columnHelper.accessor("businessName", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Business name</span>,
  }),
  columnHelper.accessor("profession", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Profession</span>,
  }),
  columnHelper.accessor("role", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>Role</span>,
  }),
  columnHelper.accessor("state", {
    cell: (info) => info.getValue() || "-",
    header: () => <span>State</span>,
  }),
  columnHelper.accessor("suspendedUntil", {
    cell: (info) =>
      info.getValue()
        ? new Date(info.getValue()).toLocaleDateString("en-US")
        : "-",
    header: () => <span>Suspended Until</span>,
  }),
];

type UsersPageProps = {
  className?: string;
};
const UsersPage = (props: UsersPageProps) => {
  const [suspendedUntil, setSuspendedUntil] = useState<Date>();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [nameSearch, setNameSearch] = useState<string>("");

  const [showPendingVerifyUsers, setShowPendingVerifyUsers] = useState(false);

  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const hasFiles = (row: User) =>
    row.arbitratorTerms?.certificates?.some(
      (el: any) => el.state === "pending-verification"
    ) ||
    row.arbitratorTerms?.languages?.some(
      (el: any) => el.state === "pending-verification"
    ) ||
    row.arbitratorTerms?.educations?.some(
      (el: any) => el.state === "pending-verification"
    );

  const getUsers = useCallback(
    async (options: { pageIndex: number; pageSize: number }) => {
      const searchQuery = nameSearch
        ? { firstName: { LIKE: `%${nameSearch}%` } }
        : {};

      const verifyQuery = [
        {
          "arbitratorTerms.educations.state": {
            EQUAL: "pending-verification",
          },
          ...searchQuery,
        },
        {
          "arbitratorTerms.languages.state": {
            EQUAL: "pending-verification",
          },
          ...searchQuery,
        },
        {
          "arbitratorTerms.certificates.state": {
            EQUAL: "pending-verification",
          },
          ...searchQuery,
        },
        {
          "businessDocs.id": {
            "!ISNULL": "",
          },
          hasVerifiedBusiness: {
            EQUAL: false,
          },
          ...searchQuery,
        },
      ];

      const where = [...(showPendingVerifyUsers ? verifyQuery : [searchQuery])];

      try {
        const { data } = await api.users.getUsers({
          $take: options.pageSize,
          $page: options.pageIndex + 1,
          $order: "-id",
          $relations: "arbitratorTerms,businessDocs",
          $where: JSON.stringify(where),
        });
        if (data) {
          return {
            rows: data.items,
            pageCount: data.totalPages,
          };
        }
        // setData(data.items);
        // return data;
      } catch (e) {
        console.error(e);
      }
    },
    [nameSearch, showPendingVerifyUsers]
  );

  const dataQuery = useQuery(
    ["users", fetchDataOptions, nameSearch, showPendingVerifyUsers],
    () => getUsers(fetchDataOptions),
    { keepPreviousData: true }
  );

  const defaultData = React.useMemo(() => [], []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  const [showSuspendTimeInput, setShowSuspendTimeInput] = useState(false);

  const banUser = useCallback(async (user: User) => {
    try {
      if (user.state === "Active") {
        await api.users.banUser(user.id);
        return;
      }
      if (user.state === "Banned") {
        await api.users.unbanUser(user.id);
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const queryClient = useQueryClient();

  // Mutations
  const mutationBan = useMutation({
    mutationFn: banUser,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["users", fetchDataOptions], (old: any) => {
        return {
          ...old,
          rows: old.rows?.map((user: any) =>
            user.id === variables.id
              ? {
                  ...variables,
                  state: variables.state === "Banned" ? "Active" : "Banned",
                }
              : user
          ),
        };
      });
    },
  });

  const suspendUser = useCallback(async (user: User) => {
    try {
      if (user.state === "Suspended") {
        await api.users.unsuspendUser(user.id);
      } else {
        await api.users.suspendUser(user.id, user.suspendedUntil);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const mutationSuspend = useMutation({
    mutationFn: suspendUser,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["users", fetchDataOptions], (old: any) => {
        return {
          ...old,
          rows: old.rows?.map((user: any) =>
            user.id === variables.id
              ? {
                  ...variables,
                  suspendedUntil:
                    variables.state === "Suspended"
                      ? undefined
                      : variables.suspendedUntil,
                  state:
                    variables.state === "Suspended" ? "Active" : "Suspended",
                }
              : user
          ),
        };
      });
      setShowSuspendTimeInput(false);
    },
  });

  return (
    <div>
      <Navigation />

      <div className="users-page">
        <div className="users-page__filters">
          <Input
            value={nameSearch}
            onChange={(ev) => setNameSearch(ev.target.value)}
            innerLabel="Search by Name"
          />

          <Checkbox
            onChange={(checked) => {
              setShowPendingVerifyUsers(checked as boolean);
            }}
            label="Show only users needing verification"
            checked={showPendingVerifyUsers}
          />
        </div>
        <div className="anys-dash__table">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <Button
                        variant="outline"
                        onClick={() => {
                          mutationBan.mutate(row.original);
                        }}
                      >
                        {row.original.state === "Banned" ? "Activate" : "Ban"}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          if (row.original.state === "Suspended") {
                            mutationSuspend.mutate(row.original);
                          } else {
                            setShowSuspendTimeInput(!showSuspendTimeInput);
                            setSelectedUser(row.original);
                          }
                        }}
                      >
                        {row.original.state === "Suspended"
                          ? "Unsuspend"
                          : "Suspend"}
                      </Button>
                      {hasFiles(row.original) && (
                        <Button
                          variant="outline"
                          onClick={() => navigate(`${row.original.id}`)}
                        >
                          Verify files
                        </Button>
                      )}

                      {!row.original.hasVerifiedBusiness &&
                        row.original.businessDocs?.length > 0 && (
                          <Button
                            variant="outline"
                            onClick={() => navigate(`${row.original.id}`)}
                          >
                            Verify business
                          </Button>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <Modal
              open={showSuspendTimeInput}
              controlledByParent
              title="Suspend until"
              onClose={() => setShowSuspendTimeInput(false)}
            >
              <CalendarDropdown
                value={suspendedUntil}
                onChange={(value: any) => {
                  setSuspendedUntil(value);
                }}
                label="To date"
                leftLimitDate={dayjs().add(1, "day")}
                rightLimitDate={dayjs().add(2, "years")}
              />

              <p>{new Date(suspendedUntil).toLocaleDateString("en-US")}</p>

              <Button
                onClick={() => {
                  mutationSuspend.mutate({
                    ...selectedUser,
                    suspendedUntil: suspendedUntil.toISOString(),
                  });
                }}
              >
                Suspend
              </Button>
            </Modal>
          </table>
          <div className="h-2" />
          <div className="flex items-center gap-2">
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="flex items-center gap-1">
              | Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="border p-1 rounded w-16"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            {dataQuery.isFetching ? "Loading..." : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
