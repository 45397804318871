import dayjs, { Dayjs } from 'dayjs';

const MIN_DATEPICKER_LIMIT = dayjs().subtract(20, 'year');
const MAX_DATEPICKER_LIMIT = dayjs().add(20, 'year');

const dateDifferces = (
  Date1: Date | Dayjs | string,
  Date2: Date | Dayjs | string,
): string => {
  const date1 = dayjs(Date1);
  const date2 = dayjs(Date2);

  let months = date2.diff(date1, 'months');

  const years = date2.diff(date1, 'years');

  months = months - years * 12;

  return `${years !== 0 ? `${years} ${years > 1 ? 'yrs' : 'yr'}` : ''} ${
    months !== 0 ? `${months} ${months > 1 ? 'mos' : 'mth'}` : ''
  }`;
};

const dateToISOString = (date: string | Date | Dayjs) =>
  dayjs(date).toISOString();

const formatDate = (date: string | Date | Dayjs, formatString = 'DD/MM/YYYY') =>
  dayjs(date).format(formatString);

const formatDeadlineDate = (date: string | Date | Dayjs) =>
  formatDate(date, 'DD/MM/YYYY • hh:mm a');

const getDatesInRange = (startDate: Dayjs, endDate: Dayjs) => {
  let start = startDate;
  const dates: Dayjs[] = [];

  while (Number(endDate.diff(start, 'days')) >= 0) {
    dates.push(start);
    start = start.add(1, 'day');
  }

  return dates;
};

const formatDates = (
  startDate: Date | Dayjs | string,
  endDate: Date | Dayjs | string,
  format = 'DD/MM/YYYY, hh:mm a',
) => {
  const isSameStartAndEnd =
    startDate && endDate && dayjs(startDate).isSame(dayjs(endDate), 'day');

  const formattedStart = startDate ? formatDate(startDate, format) : '-';
  const formattedEnd = endDate ? formatDate(endDate, format) : '-';

  return isSameStartAndEnd
    ? `${formattedStart} - ${formatDate(endDate, 'hh:mm a')}`
    : `${formattedStart} - ${formattedEnd}`;
};

export default {
  MIN_DATEPICKER_LIMIT,
  MAX_DATEPICKER_LIMIT,
  dateDifferces,
  dateToISOString,
  formatDeadlineDate,
  formatDate,
  getDatesInRange,
  formatDates,
};
