import useCallbackRef from "hooks/useCallbackRef";
import useCurrentLocation, { PlaceFormat } from "hooks/useCurrentLocation";
import useEffectSkipFirst from "hooks/useEffectSkipFirst";
// import CurrentUserContext from "providers/CurrentUser/CurrentUser.context";
import { useCallback, useContext, useEffect, useId, useState } from "react";
// import { getCurrentLocationAsInputOption } from "utils/location";

const formatPlace = (value: PlaceFormat) =>
  value?.city && value?.country ? `${value.city}, ${value.country}` : "";

type LocationAutocomplete = {
  value?: PlaceFormat;
  useProfileLocation?: boolean;
  queryRemovesValue?: boolean;
  locationPromptInitially?: boolean;
  onPlaceSelect: (value: PlaceFormat) => void;
};

const useLocationAutocomplete = (props: LocationAutocomplete) => {
  const {
    value,
    useProfileLocation,
    queryRemovesValue = true,
    locationPromptInitially,
    onPlaceSelect,
  } = props;

  const currentLocationNodeId = useId();

  const [query, setQuery] = useState<string>(formatPlace(value));
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [autoCompleteInput, autoCompleteInputRef] =
    useCallbackRef<HTMLInputElement>();

  const [resetCurrentLocation, setResetCurrentLocation] = useState(
    !locationPromptInitially
  );

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  // const { currentUser } = useContext(CurrentUserContext);

  const onCurrentLocationSuccess = useCallback(
    (place: PlaceFormat) => {
      if (value && !resetCurrentLocation) return;

      // onPlaceSelect?.(
      //   getCurrentLocationAsInputOption(place, currentUser?.location)
      // );
    },
    [resetCurrentLocation, value]
  );

  const { getCurrentLocation } = useCurrentLocation({
    callInitially: locationPromptInitially,
    onFinished: onCurrentLocationSuccess,
  });

  const pacContainers = document.getElementsByClassName("pac-container");
  const hasPacContainers = pacContainers?.length > 0;

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    []
  );

  const onInputBlur = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  const onInputFocus = useCallback(() => {
    setIsInputFocused(true);
  }, []);

  const onGetCurrentLocation = useCallback(() => {
    setResetCurrentLocation(true);

    getCurrentLocation();
  }, [getCurrentLocation]);

  useEffectSkipFirst(() => {
    if (isInputFocused || typeof value == "undefined") return;

    if (formatPlace(value) !== query) {
      onPlaceSelect?.({ ...value });
      setQuery(formatPlace(value));
    }
  }, [value]);

  useEffect(() => {
    if (!autoCompleteInput) return;

    const autocompleteInstance = new window.google.maps.places.Autocomplete(
      autoCompleteInput,
      {
        types: ["(cities)"],
      }
    );

    autocompleteInstance.setFields([
      "address_components",
      "name",
      "formatted_address",
      "geometry",
    ]);

    setAutocomplete(autocompleteInstance);

    return () => {
      if (autocompleteInstance)
        google.maps.event.clearInstanceListeners(autocompleteInstance);

      const el = document.getElementsByClassName("pac-container");

      if (!el.length) return;

      el[el.length - 1].remove();
    };
  }, [autoCompleteInput]);

  // Add 'Use profile location' node
  useEffect(() => {
    if (!useProfileLocation || !autocomplete) return;

    // create 'use location from profile' option
    const node = document.createElement("div");

    const textnode = document.createTextNode("Use location from profile");
    node.appendChild(textnode);

    const icon = document.createElement("img");
    icon.src = "assets/icons/current-location.svg";
    node.appendChild(icon);
    node.appendChild(textnode);
    node.id = currentLocationNodeId;

    node.classList.add("pac-item", "pac-item__my-current-loc");

    const lastContainerIndex = pacContainers.length - 1;

    const lastPacContainer = pacContainers[lastContainerIndex];

    if (!hasPacContainers) return;

    // Add only if we haven't added it already.
    // We check the last pac-container mounted.
    if (
      !lastPacContainer?.children?.[0]?.classList?.contains(
        "pac-item__my-current-loc"
      )
    ) {
      node.addEventListener("mousedown", onGetCurrentLocation);

      lastPacContainer?.appendChild(node);
    }

    return () => {
      node.removeEventListener("mousedown", onGetCurrentLocation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocomplete, useProfileLocation, hasPacContainers]);

  useEffect(() => {
    if (!autocomplete) return;

    const handlePlaceSelect = (
      addressObject: google.maps.places.PlaceResult
    ) => {
      onPlaceSelect?.(
        addressObject
          ? {
              city: addressObject.name,
              lat: addressObject.geometry?.location?.lat(),
              lng: addressObject.geometry?.location?.lng(),
              country: addressObject.address_components?.filter((address) =>
                address.types.includes("country")
              )?.[0]?.long_name,
            }
          : null
      );

      setResetCurrentLocation(false);
    };

    const placeChangedListener = autocomplete.addListener(
      "place_changed",
      () => {
        const addressObject = autocomplete.getPlace();

        handlePlaceSelect(addressObject);
      }
    );

    return () => {
      google.maps.event.removeListener(placeChangedListener);
    };
  }, [autocomplete, onPlaceSelect]);

  useEffect(() => {
    if (typeof value === "undefined") return;

    const val = formatPlace(value);

    setQuery(val);
  }, [value]);

  // If the text inside the input changes from the selected
  // location, remove the location from state
  useEffect(() => {
    if (!queryRemovesValue) return;

    if (!query) {
      onPlaceSelect?.(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    query,
    setQuery,
    onInputFocus,
    onInputBlur,
    autoCompleteInputRef,
    onInputChange,
  };
};

export default useLocationAutocomplete;
