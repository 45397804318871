export const formatValueCurrency = (value) => {
  const numberFormatOptions = {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat("en-US", numberFormatOptions).format(value);
};
