import React, { useCallback, useMemo } from 'react';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import Input, { InputProps } from 'components/Input/Input.component';
import formValidators from 'utils/formValidators';

export type InputFieldProps = InputProps & UseFieldConfig<string, string>;

type InputFieldComponentProps = InputProps &
  FieldRenderProps<string, HTMLElement>;


const InputFieldComponent: React.FC<InputFieldComponentProps> = (props) => {
  const { input, meta, onChange, ...restOfProps } = props;

  const error = useMemo(() => formValidators.getErrorFromMeta(meta), [meta]);

  const { onChange: inputOnChange, ...restOfInput } = useMemo(
    () => input,
    [input],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      inputOnChange(event);

      onChange?.(event);
    },
    [inputOnChange, onChange],
  );

  return (
    <Input
      {...restOfProps}
      {...restOfInput}
      {...error}
      onChange={handleOnChange}
    />
  );
};

const InputField: React.FC<InputFieldProps> = (props) => (
  <Field component={InputFieldComponent} {...props} />
);

export default InputField;
