import React, { useCallback, useEffect, useState } from "react";
import FilePreview from "components/FileItem";
import useHeightAnimation from "hooks/useHeightAnimation";
import UpArrow from "icons/UpArrow";
import DownArrow from "icons/DownArrow";
import api from "api";
import { File, NonImageFile, Picture } from "models/File";
import VerifyIcon from "icons/Verify.icon";
import classNames from "classnames";
import {
  AnyDocument,
  Category,
  Certificate,
  Education,
  Language,
  VerificationBody,
} from "models/ArbitratorTerms";

import "./FilesSection.styles.scss";

type FilesSectionProps = {
  category: Category;
  categoryArray: Array<
    Language | Certificate | Education | Picture | AnyDocument | NonImageFile
  >;
  arbitratorTermsId: number;
  userId?: number;
  isVerified?: boolean;
  handleVerification?: () => void;
};

const FilesSection = (props: FilesSectionProps) => {
  const {
    category,
    categoryArray,
    arbitratorTermsId,
    userId,
    isVerified,
    handleVerification,
  } = props;

  const { isExpanded, toggleIsExpanded, contentRef, contentHeight } =
    useHeightAnimation(false);

  const classes = classNames(["anys-files-section"]);
  const [categoryElements, setCategoryElements] =
    useState<
      Array<
        | Language
        | Certificate
        | Education
        | AnyDocument
        | Picture
        | NonImageFile
      >
    >();

  useEffect(() => {
    setCategoryElements(categoryArray);
  }, [categoryArray]);

  const handleVerifyDocument = useCallback(
    async (id: number) => {
      try {
        const body: VerificationBody =
          category === "Languages"
            ? { languageId: id }
            : category === "Educations"
              ? { educationId: id }
              : category === "Any documents"
                ? { documentId: id }
                : { certificateId: id };

        if (category === "Business documents") {
          await api.users.verifyUserBusiness(userId);
        } else {
          await api.users.verifyDocument(arbitratorTermsId, body);
        }

        toggleIsExpanded();

        const updatedArray = categoryArray.filter(
          (
            element:
              | Language
              | Certificate
              | Education
              | Picture
              | AnyDocument
              | NonImageFile,
          ) => element.id !== id,
        );

        setCategoryElements(updatedArray);
        handleVerification();
      } catch {
        console.error("Error");
      }
    },
    [
      arbitratorTermsId,
      category,
      categoryArray,
      handleVerification,
      toggleIsExpanded,
      userId,
    ],
  );
  return (
    <div className={classes}>
      <button
        onClick={toggleIsExpanded}
        className="anys-files-section__category-name"
      >
        <h2>{category}</h2>
        {isExpanded ? <UpArrow /> : <DownArrow />}
      </button>
      <div
        style={{
          height: isExpanded ? contentHeight : 0,
          overflow: "hidden",
          transition: "height 0.5s ease",
        }}
        className="anys-files-section__content"
        ref={contentRef}
      >
        {categoryElements?.map((el) => (
          <div key={el.id}>
            <div className="anys-files-section__content__header">
              <h3>
                <>
                  {category === "Languages" &&
                  "language" in el &&
                  "proficiency" in el
                    ? `${el.language} - ${el.proficiency}`
                    : category === "Educations" && "schoolName" in el
                      ? el.schoolName
                      : "name" in el
                        ? el.name
                        : null}
                  {category === "Any documents" && "description" in el
                    ? el.description
                    : null}
                </>
              </h3>
              {!isVerified && (
                <button onClick={() => handleVerifyDocument(el.id)}>
                  <VerifyIcon />
                  Verify documents
                </button>
              )}
            </div>
            <div className="anys-files-section__content__files">
              {"files" in el ? (
                el.files?.map((file: File) => (
                  <FilePreview key={file.id} name={file.name} url={file.url} />
                ))
              ) : "url" in el ? (
                <FilePreview key={el.id} name={el.name} url={el.url} />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesSection;
