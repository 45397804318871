import React  from "react";
import classNames from "classnames";
import { SubmissionErrors } from "final-form";
import { Button } from "ncoded-component-library";
import { Form, FormProps } from "react-final-form";
import { useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import PasswordField from "components/fields/PasswordField";

import "./LoginForm.styles.scss";


type LoginFormProps = {
  className?: string;
  onSubmit: (
    values: any
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
  inProgress?: boolean;
} & FormProps<any>;

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { className, initialValues, onSubmit, inProgress } = props;


  const classes = classNames("anys-login-form", className);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={(formRenderProps) => {
        const { handleSubmit, invalid } = formRenderProps;

        const submitDisabled = invalid || inProgress;

        return (
          <form className={classes} onSubmit={handleSubmit}>
            <InputField
              type="email"
              name="email"
              label="Email"
              // validate={validators.email}
            />

            <PasswordField
              name="password"
              label="Password"
              // validate={validators.password}
              hidePasswordStrength
            />

            <Button
              type="submit"
              disabled={submitDisabled}
              variant="outline"
              className="anys-login__login-btn"
            >
              Login
            </Button>
          </form>
        );
      }}
    />
  );
};

export default LoginForm;
