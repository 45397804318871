import { BrowserRouter } from "react-router-dom";
import Routes from "./components/Routes";
import routes from "./routes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes routes={routes} />
    </BrowserRouter>
  );
};

export default Router;
