import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import api from "api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CalendarDropdown from "components/CalendarDropdown";
import dayjs from "dayjs";
import { Select } from "ncoded-component-library";
import Navigation from "components/Navigation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

type ReportPageProps = {
  className?: string;
};

type ContractStatsType = {
  date: string;
  numberOfStartedArbitrations: number;
  numberOfJobPostsWithArbitrationOption: number;
  numberOfJobsWithArbitrationOption: number;
  numberOfContractsWithArbitrationOption: number;
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
    },
  },
};

const ArbitrationsPage = (props: ReportPageProps) => {
  const { className } = props;
  const classes = classNames("anys-report-page", className);

  const [arbitrationsStats, setArbitrationsStats] = useState<
    ContractStatsType[]
  >([]);

  const [jobTypeFilter, setJobTypeFilter] = useState("contract");

  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "day"),
    end_date: dayjs(),
  });

  const getArbitrations = useCallback(async () => {
    try {
      const queryParams = {
        fromDate: dateFilter.start_date.format("YYYY-MM-DD"),
        toDate: dateFilter.end_date.format("YYYY-MM-DD"),
      };

      const { data } = await api.arbitrations.getArbitrationsStats(queryParams);

      if (data) {
        setArbitrationsStats(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dateFilter.end_date, dateFilter.start_date]);

  const labels = useMemo(
    () =>
      arbitrationsStats.length > 0
        ? arbitrationsStats.map((contractStat) => contractStat.date)
        : [],
    [arbitrationsStats]
  );

  const startedArbitrationData = useMemo(
    () =>
      arbitrationsStats.map(
        (arbitrationsStat) => arbitrationsStat.numberOfStartedArbitrations || 0
      ),
    [arbitrationsStats]
  );

  const startedArbitrations = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          label: "Started arbitrations",
          data: startedArbitrationData,
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [labels, startedArbitrationData]
  );

  const arbitrationsValues = useMemo(() => {
    if (jobTypeFilter === "job_post") {
      return arbitrationsStats.map(
        (arbitrationsStat) =>
          arbitrationsStat.numberOfJobPostsWithArbitrationOption || 0
      );
    }
    if (jobTypeFilter === "job") {
      return arbitrationsStats.map(
        (arbitrationsStat) =>
          arbitrationsStat.numberOfJobsWithArbitrationOption || 0
      );
    }
    if (jobTypeFilter === "contract") {
      return arbitrationsStats.map(
        (arbitrationsStat) =>
          arbitrationsStat.numberOfContractsWithArbitrationOption || 0
      );
    }
  }, [arbitrationsStats, jobTypeFilter]);

  const arbitrationLabel = useMemo(() => {
    return jobTypeFilter === "job_post"
      ? "Job post arbitrations"
      : jobTypeFilter === "job"
      ? "Job arbitrations"
      : "Contract arbitrations" || "";
  }, [jobTypeFilter]);

  const arbitrationsByState = useMemo(
    () => ({
      labels,
      datasets: [
        {
          fill: true,
          label: arbitrationLabel,
          data: arbitrationsValues,
          borderColor: "rgb(53, 162, 235)",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    }),
    [labels, arbitrationLabel, arbitrationsValues]
  );

  useEffect(() => {
    getArbitrations();
  }, [getArbitrations]);

  return (
    <div className={classes}>
      <Navigation />

      <div className="anys-report-page__filter">
        <div className="anys-report-page__filter__dates">
          <CalendarDropdown
            value={dateFilter.start_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, start_date: value }));
            }}
            label="Start date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs().subtract(1, "day")}
          />

          <CalendarDropdown
            value={dateFilter.end_date}
            onChange={(value: any) => {
              setDateFilter((old) => ({ ...old, end_date: value }));
            }}
            label="End date"
            leftLimitDate={dayjs().subtract(2, "years")}
            rightLimitDate={dayjs()}
          />
        </div>
      </div>
      <div className="anys-report-page__job-type">
        <Select
          innerLabel="Select type"
          options={[
            { label: "Job post", value: "job_post" },
            { label: "Job", value: "job" },
            { label: "Contract", value: "contract" },
          ]}
          value={jobTypeFilter as any}
          onChange={(value: any) => setJobTypeFilter(value.value)}
        />
      </div>
      <div className="anys-report-page__charts">
        <div>
          <div className="anys-report-page__charts__total">
            <b>
              Total:{" "}
              {startedArbitrationData?.reduce(
                (total, stat) => (total += stat),
                0
              )}
            </b>
          </div>
          <Line options={options} data={startedArbitrations} />
        </div>

        <div>
          <div className="anys-report-page__charts__total">
            <b>
              Total:{" "}
              {arbitrationsValues?.reduce((total, stat) => (total += stat), 0)}
            </b>
          </div>
          <Line options={options} data={arbitrationsByState} />
        </div>
      </div>
    </div>
  );
};

export default ArbitrationsPage;
