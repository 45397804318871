import { FieldMetaState } from "react-final-form";

const getErrorFromMeta = <T>(
  meta: FieldMetaState<T>
): { hasError: boolean; error: string } => {
  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return { hasError, error };
};

export default {
  getErrorFromMeta,
};
